import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Spline from '@splinetool/react-spline';
import AuthPage from './pages/AuthPage';
import AIChatPage from './pages/AIChatPage';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <div className="w-screen h-screen">
            <Spline
              scene="https://prod.spline.design/vYkuvKC6JtW17BWF/scene.splinecode"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        } />
        <Route path="/ai" element={<AuthPage />} />
        <Route path="/ai/chat" element={<AIChatPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;