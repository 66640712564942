const API_URL = 'https://api.openai.com/v1/chat/completions';

export const sendMessage = async (messages) => {
  console.log('API: Starting sendMessage with messages:', messages);
  
  try {
    const recentMessages = messages.slice(-10);
    
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: recentMessages,
        stream: true,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API request failed with status ${response.status}: ${errorText}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let content = '';

    return {
      async* streamResponse() {
        let leftover = '';

        while (true) {
          const { done, value } = await reader.read();
          
          if (done) break;
          
          // Decode the chunk and combine with any leftover data
          const chunk = decoder.decode(value, { stream: true });
          const allData = leftover + chunk;
          
          // Split into lines and keep the last partial line
          const lines = allData.split('\n');
          leftover = lines[lines.length - 1];

          // Process all complete lines except the last one
          for (let i = 0; i < lines.length - 1; i++) {
            const line = lines[i].trim();
            if (!line) continue;
            if (line === 'data: [DONE]') continue;
            if (!line.startsWith('data: ')) continue;

            try {
              // Remove 'data: ' prefix and parse
              const data = line.slice(6);
              if (!data) continue;

              const parsed = JSON.parse(data);
              const text = parsed.choices?.[0]?.delta?.content;
              
              if (text) {
                content += text;
                yield text;
              }
            } catch (e) {
              console.error('Parse error on line:', line);
              console.error('Error:', e);
              // Continue processing other lines
              continue;
            }
          }
        }

        // Process any remaining data in leftover
        if (leftover && leftover.startsWith('data: ') && leftover !== 'data: [DONE]') {
          try {
            const data = leftover.slice(6);
            const parsed = JSON.parse(data);
            const text = parsed.choices?.[0]?.delta?.content;
            if (text) {
              content += text;
              yield text;
            }
          } catch (e) {
            console.error('Parse error on leftover:', leftover);
          }
        }

        return content;
      }
    };
  } catch (error) {
    console.error('API: Error in sendMessage:', error);
    throw error;
  }
}; 