import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatBox from '../components/ChatBox';
import { sendMessage } from '../utils/api';

const AIChatPage = () => {
  console.log('AIChatPage: Component rendered');
  
  const [messages, setMessages] = useState([
    { 
      id: 'initial',
      role: 'assistant', 
      content: 'Hello! How can I assist you today?' 
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('AIChatPage: Checking authentication');
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (isAuthenticated !== 'true') {
      console.log('AIChatPage: Not authenticated, redirecting');
      navigate('/ai');
    }
  }, [navigate]);

  const updateAssistantMessage = useCallback((content) => {
    setMessages(prev => {
      const newMessages = [...prev];
      newMessages[newMessages.length - 1] = {
        role: 'assistant',
        content
      };
      return newMessages;
    });
  }, []);

  const handleSendMessage = async (content) => {
    console.log('AIChatPage: handleSendMessage called with content:', content);
    
    if (isLoading) {
      console.log('AIChatPage: Prevented send while loading');
      return;
    }

    const userMessage = { role: 'user', content };
    const currentMessages = [...messages, userMessage];
    setMessages(currentMessages);
    setIsLoading(true);
    
    try {
      console.log('AIChatPage: Calling sendMessage API');
      const stream = await sendMessage(currentMessages);
      
      let accumulatedContent = '';
      // Create a temporary ID for this response
      const responseId = Date.now();
      
      // Add initial empty assistant message
      setMessages(prev => [...prev, { 
        id: responseId,
        role: 'assistant', 
        content: '' 
      }]);
      
      console.log('AIChatPage: Starting to process stream');
      for await (const chunk of stream.streamResponse()) {
        accumulatedContent += chunk;
        setMessages(prev => 
          prev.map(msg => 
            msg.id === responseId 
              ? { ...msg, content: accumulatedContent }
              : msg
          )
        );
      }
      
      console.log('AIChatPage: Stream processing complete');
    } catch (error) {
      console.error('AIChatPage: Error in handleSendMessage:', error);
      setMessages(prev => [...prev, { 
        role: 'assistant', 
        content: 'Sorry, there was an error processing your request.' 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ChatBox
      messages={messages}
      onSendMessage={handleSendMessage}
      isLoading={isLoading}
    />
  );
};

export default AIChatPage; 